<template>
  <div class="main">
    <div class="clients-listing">

      <div class="clients-listing-header">
        <PageTitle />

        <div class="filters">
          <form>
            <input @keyup="handleSearchSort" class="form-input" type="search" placeholder="rechercher" v-model="search">
            <select @change="handleSearchSort" class="form-select" v-model="sort">
              <option hidden value="">Afficher par :</option>
              <option value="DESC">Les plus récents</option>
              <option value="ASC">Les moins récents</option>
            </select>
            <router-link class="btn btn-red" :to="{ name: 'clientsAdd'}">+</router-link>
          </form>
        </div>

      </div>

      <div class="clients-listing-content">
        <div v-if="clients.length" class="table-container" role="table">
          <div
              v-for="(client, clientIndex) in clients"
              :key="clientIndex"
              class="client" role="rowgroup">

              <div class="client-infos">
                <div @click="showQuotes(client.id)" class="cell icon" :class="{ 'dark-background': client.id === visibleQuotesClient }" role="cell">
                  <icon :icon="'arrow-down-circle'" />
                </div>
                <div class="cell" role="cell">{{ client.companyName }}</div>
                <div class="cell" role="cell">{{ client.lastname }}</div>
                <div class="cell" role="cell">{{ client.firstname }}</div>
                <div class="cell" role="cell">{{ client.city }}</div>
                <div class="cell" role="cell">{{ client.email }}</div>
                <div @click="$router.push({ name: 'clientsEdit', params: { clientId: client.id } })" class="cell icon" role="cell">
                  <icon :icon="'eye'" />
                </div>
              </div>
              <div v-show="client.id === visibleQuotesClient" class="client-quotes">
                <div class="quotes" :class="{ 'no-quote': !client.quotes.length }">
                  <div v-if="!client.quotes.length">Aucune simulation</div>
                  <div
                      v-for="(quote, quoteIndex) in client.quotes"
                      :key="quoteIndex"
                      class="quote" :class="{ 'highlighted': quote.id === highlightedQuote }">
                    <div class="cell" role="cell">{{ quote.title }}</div>
                    <div class="cell" role="cell">{{ quote.created }}</div>
                    <div class="cell" role="cell">{{ quote.prixHT }}€</div>
                    <div class="cell" role="cell">{{ quote.ri.length === 10 ? '+ ' + Math.round(quote.ri[9] * 100) + '%'  : 'RI non calculé' }}</div>
                    <div class="cell icons" role="cell">

                      <modal close-left>
                        <div
                          @click="openModal(quote.id)"
                          slot="activator">
                          <icon :icon="'eye-white'" />
                        </div>
                        <Validate />
                      </modal>

                      <div @click="editQuote(client.id, quote.id)"><icon :icon="'pen-white'" /></div>
                      <div
                        @click="sendQuote(quote.id, client.email, quote.title)">
                        <icon :icon="'email-white'" />
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  @click="addQuote(client.id)"
                  class="btn btn-red edit-btn">+</button>
              </div>
          </div>
        </div>
        <div v-else class="table-container" role="table">
          <div class="client">
            <div class="client-infos">
              <div class="cell">Aucun client</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/commons/PageTitle";
import Validate from "@/components/quotes/summary/Validate";

export default {
  components: {Validate, PageTitle},

  data: () => ({
    visibleQuotesClient: null,
    search: '',
    sort: ''
  }),

  computed: {
    clients() {
      return this.$store.state.clients.listing;
    },

    highlightedQuote() {
      return this.$store.state.clients.listingHighlightedQuote
    }
  },

  methods: {
    showQuotes (clientId) {
      if(this.visibleQuotesClient === clientId) {
        this.visibleQuotesClient = null;
        return;
      }
      this.visibleQuotesClient = clientId;
    },

    handleSearchSort() {
      const params = { s: this.search, created: this.sort }
      this.$store.dispatch('getClients', params)
    },

    async openModal(quoteId) {
      await this.$store.dispatch('getQuote', quoteId);
      await this.$store.dispatch('getTaxos');
      await this.$store.dispatch('getProduits')
    },

    addQuote(clientId) {
      const client = this.clients.find((client) => {
        return client.id === clientId
      })
      const firstQuote = client?.quotes?.[0]

      if (firstQuote) {
        this.$router.push({ name: 'QuotesDuplicateElevage', params: { clientId, quoteId: firstQuote.id }})

      } else {
        this.$router.push({ name: 'QuotesElevage', params: { clientId }})
      }
    },

    editQuote(clientId, quoteId) {
      this.$router.push({ name: 'QuotesEditElevage', params: { clientId, quoteId }})
    },

    sendQuote(id, to, filename) {
      this.$store.dispatch('sendQuote', { id, to, filename})
    }
  },

  beforeMount() {
    this.$store.dispatch('getClients')
  },

  beforeRouteEnter(to, from, next) {
    if(from.name === 'QuotesValidate' || from.name === 'QuotesEditValidate') {
        next(vm => {
          vm.visibleQuotesClient = parseInt(from.params.clientId)
        })
    }
    next()
  }
}
</script>

<style lang="scss" scoped>

</style>
