<template>
    <modal>
      <button slot="activator" class="btn btn-red">Détails de financement du projet</button>

      <div ref="quoteChart" class="quote-chart">

        <div class="quote-chart-header">

          <p>Récapitulatif de <strong>votre projet</strong></p>

          <p class="quote-chart-header-investment">Coût de l'investissement : <span>{{ prixHT }}</span></p>

        </div>

        <div class="quote-chart-cards">

          <div class="quote-chart-cards-benefits">
            <p>Gains nets sur 10 ans</p>
            <p>
              <span>{{ gains }}</span><br>
              {{ rentabilisationAnnee ? '(Projet rentabilisé dès ' + rentabilisationAnnee + ')' : '' }}
            </p>
          </div>

          <div class="quote-chart-cards-treasury7">
            <p>Solde de trésorerie cumulé avec un financement sur 7 ans*</p>
            <p>{{ tresorerie7Annee10 }}</p>
          </div>

          <div class="quote-chart-cards-treasury10">
            <p>Solde de trésorerie cumulé avec un financement sur 10 ans*</p>
            <p>{{ tresorerie10Annee10 }}</p>
          </div>

        </div>

        <p class="mt-2 text-right text-gray-900">* à un taux de 2,5% par an et mensualités constantes</p>

        <div class="quote-chart-graph">

          <LineChartGenerator
            :chart-options="chartOptions"
            :chart-data="chartData"
            chart-id="ri-chart"
            dataset-id-key="labels"
          />

        </div>

        <p class="text-gray-900 text-center">Simulation non contractuelle</p>

      </div>

    </modal>

</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation';

ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement, annotationPlugin)

export default {
  name: 'Chart',

  components: { LineChartGenerator },

  data: function () {
    return {
      chartData: {
        labels: [ '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10' ],
        datasets: [
          {
            label: 'Solde de trésorerie cumulé avec un financement sur 7 ans',
            backgroundColor: '#616161',
            borderColor: '#616161',
            borderWidth: 3,
            pointStyle: 'rect',
            pointRadius: 8,
            data: []
          },
          {
            label: 'Solde de trésorerie cumulé avec un financement sur 10 ans',
            backgroundColor: '#ef5407',
            borderColor: '#ef5407',
            borderWidth: 3,
            pointStyle: 'rect',
            pointRadius: 8,
            hidden: true,
            data: []
          },
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            title: {
              display: true,
              text: 'Années'
            }
          }
        },
        plugins: {
          autocolors: false,
          annotation: {
            annotations: {
              line0: {
                type: 'line',
                yMin: 0,
                yMax: 0,
                borderColor: '#616161',
                borderWidth: 1,
              }
            }
          }
        }
      }
    }
  },

  computed: {
    values() {
      const quote = this.$store.state.quotes.current
      return { ...quote.elevage, ...quote.logettes, ...quote.batiment }
    },

    prixHT() {
      return this.$store.state.quotes.current.prixHT > 0 ?
        this.$store.state.quotes.current.prixHT + ' € HT' : 'Non calculé'
    },

    gains() {
      return this.$store.state.quotes.current.gains > 0 ?
        this.$store.state.quotes.current.gains + ' €' : 'Non calculé'
    },

    rentabilisationAnnee() {
      return this.$store.state.quotes.current.rentabilisationAnnee
    },

    tresorerie7() {
      return this.$store.state.quotes.current.tresorerie7.length === 10 ?
        this.$store.state.quotes.current.tresorerie7 : []
    },

    tresorerie10() {
      return this.$store.state.quotes.current.tresorerie10.length === 10 ?
        this.$store.state.quotes.current.tresorerie10 : []
    },

    tresorerie7Annee10() {
      return this.$store.state.quotes.current.tresorerie7.length === 10 ?
        Math.round(this.$store.state.quotes.current.tresorerie7[9]) + ' €' : 'Non calculé'
    },

    tresorerie10Annee10() {
      return this.$store.state.quotes.current.tresorerie10.length === 10 ?
        Math.round(this.$store.state.quotes.current.tresorerie10[9]) + ' €' : 'Non calculé'
    },

    taxos() {
      return this.$store.state.quotes.taxos
    }
  },

  watch: {
    tresorerie7(newValue) {
      this.chartData.datasets[0].data = [0, ...newValue]
    },

    tresorerie10(newValue) {
      this.chartData.datasets[1].data = [0, ...newValue]
    }
  }
}
</script>
