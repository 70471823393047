<template>
  <div class="main">
    <div class="w-full quote-batiment-form">
      <PageTitle />
      <form>
        <div class="form-row form-row-3">
          <div class="form-field">
            <select
              v-model.trim="$v.form.typeVentilation.$model"
              class="form-select" :class="{ 'border-red-700': $v.form.typeVentilation.$error }">
              <option
                v-for="(option, index) in typeVentilationOptions"
                :key="index"
                :value="option.id"
                :disabled="!option.id"
              >
                {{ option.name }}
              </option>
            </select>
            <span class="select">Type de ventilation :</span>
          </div>
          <div class="form-field">
            <select
              v-model.trim="$v.form.typeBatiment.$model"
              class="form-select" :class="{ 'border-red-700': $v.form.typeBatiment.$error }">
              <option
                v-for="(option, index) in typeBatimentOptions"
                :key="index"
                :value="option.id"
                :disabled="!option.id"
              >
                {{ option.name }}
              </option>
            </select>
            <span class="select">Type de bâtiment :</span>
          </div>
          <div class="form-field">
            <select
              v-model.trim="$v.form.isolationToiture.$model"
              class="form-select" :class="{ 'border-red-700': $v.form.isolationToiture.$error }">
              <option
                v-for="(option, index) in isolationToitureOptions"
                :key="index"
                :value="option.id"
                :disabled="!option.id"
              >
                {{ option.name }}
              </option>
            </select>
            <span class="select">Isolation de toiture :</span>
          </div>
        </div>
        <div class="form-submit">
          <button
              class="btn btn-red my-5"
              @click.prevent="handleSubmit">
            Valider
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/commons/PageTitle";
import { required, numeric } from 'vuelidate/lib/validators'

export default {
  components: {PageTitle},

  computed: {
    form() {
      return this.$store.state.quotes.current.batiment
    },

    typeVentilationOptions() {
      return this.$store.state.quotes.taxos.type_de_ventilation
    },

    typeBatimentOptions() {
      return this.$store.state.quotes.taxos.type_de_batiment
    },

    isolationToitureOptions() {
      return this.$store.state.quotes.taxos.isolation_de_toiture
    },
  },

  validations: {
    form: {
      typeVentilation: {
        required
      },
      typeBatiment: {
        required
      },
      isolationToiture: {
        required
      }
    }
  },

  methods: {
    handleSubmit () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$store.commit('SET_BATIMENT_VALUE', this.form)

        if(this.$route.name === 'QuotesEditBatiment') {
          this.$router.push({ name: 'QuotesEditProduits' })

        } else if (this.$route.name === 'QuotesDuplicateBatiment') {
          this.$router.push({ name: 'QuotesDuplicateProduits' })

        } else {
          this.$router.push({ name: 'QuotesProduits' })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
