<template>
  <div class="main">
    <div class="w-full quote-logettes-form">
      <PageTitle />
      <form>
        <div class="form-row form-row-2">
          <div class="form-field">
            <select
              v-model.trim="$v.form.largeurLogette.$model"
              class="form-select" :class="{ 'border-red-700': $v.form.largeurLogette.$error }">
              <option value="" selected disabled>Sélectionner :</option>
              <option value="1,15">1,15m</option>
              <option value="1,20">1,20m</option>
              <option value="1,25">1,25m</option>
            </select>
            <span class="select">Largeur de logettes :</span>
          </div>
          <div class="form-field">
            <select
              v-model.trim="$v.form.couchage.$model"
              class="form-select" :class="{ 'border-red-700': $v.form.couchage.$error }">
              <option
                v-for="(option, index) in couchageOptions"
                :key="index"
                :value="option.id"
                :disabled="index === '0'"
              >
                {{ option.name }}
              </option>
            </select>
            <span class="select">Couchage :</span>
          </div>
        </div>
        <div class="form-row form-row-3">
          <div class="form-field">
            <select
              v-model.trim="$v.form.zoneCaillouteuse.$model"
              class="form-select" :class="{ 'border-red-700': $v.form.zoneCaillouteuse.$error }">
              <option value="" selected disabled>Sélectionner :</option>
              <option value="1">Oui</option>
              <option value="0">Non</option>
            </select>
            <span class="select">Présence de cailloux dans le bâtiment :</span>
          </div>
          <div class="form-field">
            <select
              v-model.trim="$v.form.typeSols.$model"
              class="form-select" :class="{ 'border-red-700': $v.form.typeSols.$error }">
              <option
                v-for="(option, index) in typeSolsOptions"
                :key="index"
                :value="option.id"
                :disabled="!option.id"
              >
                {{ option.name }}
              </option>
            </select>
            <span class="select">Type de sol des couloirs :</span>
          </div>
          <div class="form-field">
            <select
              v-model.trim="$v.form.typeRaclage.$model"
              class="form-select" :class="{ 'border-red-700': $v.form.typeRaclage.$error }">
              <option
                v-for="(option, index) in typeRaclageOptions"
                :key="index"
                :value="option.id"
                :disabled="!option.id"
              >
                {{ option.name }}
              </option>
            </select>
            <span class="select">Type de raclage :</span>
          </div>
        </div>
        <div class="form-row form-row-center">
          <div class="form-field">
            <input
                placeholder="Nombre de places au cornadis :"
                type="text"
                v-model.trim="$v.form.nbrPlaceCornadis.$model"
                class="form-input" :class="{ 'border-red-700': $v.form.nbrPlaceCornadis.$error }">
            <span>Nombre de places au cornadis :</span>
          </div>
        </div>
        <div
          v-for="(couloir, index) in form.couloirs"
          class="form-row form-row-couloir">
          <p>Couloir {{ index + 1 }}</p>
          <div class="form-field">
            <input
                placeholder="L (m) :"
                type="text"
                v-model="form.couloirs[index].longueur"
                class="form-input">
            <span>L (m) :</span>
          </div>
          <div class="form-field">
            <input
                placeholder="l (m) :"
                type="text"
                v-model="form.couloirs[index].largeur"
                class="form-input">
            <span>l (m) :</span>
          </div>
          <div class="form-field">
            <input
                placeholder="Qté :"
                type="text"
                v-model="form.couloirs[index].qte"
                class="form-input">
            <span>Qté :</span>
          </div>
          <button
              v-if="index + 1 === form.couloirs.length"
              @click.prevent="form.couloirs.push({
              longueur: '',
              largeur: '',
              qte: ''
            })"
              class="btn btn-transparent btn-small uppercase">
            Ajouter
          </button>
          <div v-else></div>
        </div>
        <div class="form-submit">
          <button
              class="btn btn-red my-5"
              @click.prevent="handleSubmit">
            Valider
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/commons/PageTitle";
import { required, numeric } from 'vuelidate/lib/validators'

export default {
  components: {PageTitle},

  computed: {
    form() {
      return this.$store.state.quotes.current.logettes;
    },

    typeSolsOptions() {
      return this.$store.state.quotes.taxos.type_de_sols;
    },

    typeRaclageOptions() {
      return this.$store.state.quotes.taxos.type_de_raclage;
    },

    couchageOptions() {
      return this.$store.state.quotes.taxos.couchage;
    },
  },

  validations: {
    form: {
      largeurLogette: {
        required
      },
      couchage: {
        required
      },
      zoneCaillouteuse: {
        required
      },
      typeSols: {
        required
      },
      typeRaclage: {
        required
      },
      nbrPlaceCornadis: {
        numeric,
        required
      }
    }
  },

  methods: {
    handleSubmit () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$store.commit('SET_LOGETTES_VALUE', this.form)

        if(this.$route.name === 'QuotesEditLogettes') {
          this.$router.push({ name: 'QuotesEditBatiment' })

        } else if (this.$route.name === 'QuotesDuplicateLogettes') {
          this.$router.push({ name: 'QuotesDuplicateBatiment' })

        } else {
          this.$router.push({ name: 'QuotesBatiment' })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
