<template>
  <div class="quote-sidebar">
    <div class="products">
      <h2>Simulation économique des solutions Bioret Agri</h2>
      <div
          v-for="(produit, index) in produitsDevis"
          :key="index"
        >
        <div v-if="produits && produits[produit.produit] && produit.qte" class="product">
          <p class="title">{{ produits[produit.produit].name }}</p>
          <p v-if="produit.qte">Quantité : {{ produit.qte }}</p>
          <p>Prix unitaire : {{ produits[produit.produit].price.toLocaleString() }} €</p>
          <p>Prix total : {{ (produits[produit.produit].price * produit.qte).toLocaleString() }} €</p>
        </div>
      </div>
    </div>
    <div class="quote-sidebar-bottom">
      <div class="ri">
        <p>Seuil de rentabilité : {{ seuilRentabilite }} ans</p>
        <p>Retour d’investissement sur 10 ans en % :</p>
        <p>{{ ri.length === 10 ? '+ ' + Math.round(ri[9] * 100) + '%'  : 'Non calculé' }}</p>
      </div>
      <div class="total-price">
        Montant de votre projet<br>
        {{ prixHT }} € HT
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sidebar",

  computed: {
    produitsDevis() {
      return this.$store.state.quotes.current.produits
    },

    produits() {
      return this.$store.state.quotes.produits
    },

    ri() {
      return this.$store.state.quotes.current.ri
    },

    prixHT() {
      return this.$store.state.quotes.current.prixHT
    },

    seuilRentabilite() {
      const rentabilisationAnnee = this.$store.state.quotes.current.rentabilisationAnnee

      return rentabilisationAnnee - new Date().getFullYear()
    }
  }
}
</script>
