import actions from './actions'
import getters from './getters'
import mutations from './mutations'

import Cookies from 'js-cookie'
import {required} from "vuelidate/lib/validators";

const getDefaultState = () => {
  return {
    current: {
      already: false,
      companyName: '',
      lastname: '',
      firstname: '',
      address: '',
      postalCode: '',
      city: '',
      country: '',
      phone: '',
      email: ''
    },
    listing: [],
    listingHighlightedQuote: ''
  }
}

const state = getDefaultState();

mutations.RESET_CLIENTS_STATE = (state) => {
  Object.assign(state, getDefaultState())
}

export default ({
  state: state,
  actions,
  mutations,
  getters
})
