import store from "@/store";

const calculRi = () => {
  const nbrVachesTraite = store.state.quotes.current.elevage.nbrVachesTraite
  const produitsDevis = store.state.quotes.current.produits
  const allProduits = store.state.quotes.produits
  const coefficientAnnee1 = store.state.quotes.riSettings.coefficientAnnee1
  const coefficientAnnee2 = store.state.quotes.riSettings.coefficientAnnee2
  const coefficientAnnee3 = store.state.quotes.riSettings.coefficientAnnee3

  /*
  % de vaches/ nombre de logettes * Couchage * TypeRaclage * TypeVentilation * TypeBatiment * TypeIsolation
  Règles pour les coefficients :
    % de vaches/ nombre de logettes :
    1 si on a un nombre de vache en traite égale ou inférieur au nombre de places (donc 100% si on fait le calcul nNbvache/NbLogettes*100)
  1.03 si entre 100% et 105%
  1.05 si entre 106% et 110%
  1.08  pour 111% et plus.
  Couchage : Paillé 1,08 / Matelas 1,1 / Plaque 1,1 / Creuse 1,12 / Sable 1;12
  TypeRaclage : Hydrocurage 1,075 / Automatique 1,05 / Robotisé 1,05 / Tracteur 1,075
  TypeVentilation : Rideau amovible 0,95 / Ventilation mécanisé 0,85 / Aucun système 1
  TypeBatiment : Bas 1,1 / Moyen 1,05 / Haut 1
  Type d’isolation : Isolée 1 / Panneaux photovoltaïques 1 / Non isolée 1,05
  */
  const cumulISTHVL = () => {
    const nombreLogettes = store.state.quotes.current.elevage.nombreLogettes
    const couchageId = store.state.quotes.current.logettes.couchage
    const typeRaclageId = store.state.quotes.current.logettes.typeRaclage
    const typeVentilationId = store.state.quotes.current.batiment.typeVentilation
    const typeBatimentId = store.state.quotes.current.batiment.typeBatiment
    const isolationToitureId = store.state.quotes.current.batiment.isolationToiture

    const couchageCoefficient = store.state.quotes.taxos.couchage && store.state.quotes.taxos.couchage[couchageId] ?
      store.state.quotes.taxos.couchage[couchageId].coefficient : {}
    const typeRaclageCoefficient = store.state.quotes.taxos.type_de_raclage && store.state.quotes.taxos.type_de_raclage[typeRaclageId] ?
      store.state.quotes.taxos.type_de_raclage[typeRaclageId].coefficient : {}
    const typeVentilationCoefficient = store.state.quotes.taxos.type_de_ventilation && store.state.quotes.taxos.type_de_ventilation[typeVentilationId] ?
      store.state.quotes.taxos.type_de_ventilation[typeVentilationId].coefficient : {}
    const typeBatimentCoefficient = store.state.quotes.taxos.type_de_batiment && store.state.quotes.taxos.type_de_batiment[typeBatimentId] ?
      store.state.quotes.taxos.type_de_batiment[typeBatimentId].coefficient : {}
    const isolationToitureCoefficient = store.state.quotes.taxos.isolation_de_toiture && store.state.quotes.taxos.isolation_de_toiture[isolationToitureId] ?
      store.state.quotes.taxos.isolation_de_toiture[isolationToitureId].coefficient : {}

    var proportionVachesLogettes = nbrVachesTraite / nombreLogettes
    if(proportionVachesLogettes < 1) {
      proportionVachesLogettes = 1
    } else if(proportionVachesLogettes > 1 && proportionVachesLogettes <= 1.05) {
      proportionVachesLogettes = 1.03
    } else if (proportionVachesLogettes > 1.05 && proportionVachesLogettes <= 1.1) {
      proportionVachesLogettes = 1.05
    } else {
      proportionVachesLogettes = 1.08
    }

    return proportionVachesLogettes * couchageCoefficient * typeRaclageCoefficient * typeVentilationCoefficient *
      typeBatimentCoefficient * isolationToitureCoefficient
  }

  /*
  Le calcul correspond à la moyenne de perte de lait sur 3 ans.
  A la sélection de la station, on fait la somme du ISTH sur 3 ans divisé par 3.

  MOYENNE CALCULÉE SUR L'API DRUPAL
  */
  const moyISTH = () => {
    const stationMeteoId = store.state.quotes.current.elevage.stationMeteo
    return store.state.quotes.stationsMeteo[stationMeteoId] ? store.state.quotes.stationsMeteo[stationMeteoId].moyISTH : 0
  }

  /*
  nbrVachesTraite * ((((sommes des impacts produits sur le nombre de litre de lait)*365)+(MoyISTH *
  (Somme des impacts des produits sur le ISTHVL) * CumulISTHVL))*Prix du litre de lait)+(Somme des économies véto des produits))
  */
  const litreLait = () => {
    let impactLitreLaitSomme = 0
    let impactIsthvlSomme = 0
    let economieVeterinaireSomme = 0

    produitsDevis.forEach(produit => {
      if(allProduits && allProduits[produit.produit]) {
        impactLitreLaitSomme += allProduits[produit.produit].impactLitreLait
        impactIsthvlSomme += allProduits[produit.produit].impactIsthvl
        economieVeterinaireSomme += allProduits[produit.produit].economieVeterinaire
      }
    })

    const prixLitreLait = store.state.quotes.current.elevage.prixLitreLait

    return nbrVachesTraite * ((((impactLitreLaitSomme * 365) + (moyISTH() * impactIsthvlSomme * cumulISTHVL())) * prixLitreLait) + economieVeterinaireSomme)
  }

  /*
  200 * (somme coefficients d’impact produit sur la lactation) * nbrVachesTraite
  */
  const lactation = () => {
    let impactLactationSomme = 0
    produitsDevis.forEach(produit => {
      if(allProduits && allProduits[produit.produit]) {
        impactLactationSomme += allProduits[produit.produit].impactLactation
      }
    })
    return 200 * impactLactationSomme * nbrVachesTraite
  }

  /*
  (somme du coût par vache de frais véto par produit) * nbrVachesTraite
  */
  const reductionFraisVeterinaires = () => {
    let coutVeterinaireSomme = 0
    produitsDevis.forEach(produit => {
      if(allProduits && allProduits[produit.produit]) {
        coutVeterinaireSomme += allProduits[produit.produit].coutVeterinaire
      }
    })
    return coutVeterinaireSomme * nbrVachesTraite
  }

  const litreLaitAnnee4 = litreLait()
  const litreLaitAnnee1 = litreLaitAnnee4 * coefficientAnnee1
  const litreLaitAnnee2 = litreLaitAnnee4 * coefficientAnnee2
  const litreLaitAnnee3 = litreLaitAnnee4 * coefficientAnnee3

  const lactationAnnee4 = lactation()
  const lactationAnnee1 = lactationAnnee4 * coefficientAnnee1
  const lactationAnnee2 = lactationAnnee4 * coefficientAnnee2
  const lactationAnnee3 = lactationAnnee4 * coefficientAnnee3

  const reductionFraisVeterinairesAnnee4 = reductionFraisVeterinaires()
  const reductionFraisVeterinairesAnnee1 = reductionFraisVeterinairesAnnee4 * coefficientAnnee1
  const reductionFraisVeterinairesAnnee2 = reductionFraisVeterinairesAnnee4 * coefficientAnnee2
  const reductionFraisVeterinairesAnnee3 = reductionFraisVeterinairesAnnee4 * coefficientAnnee3

  const cumulImpactbrutAnnee1 = litreLaitAnnee1 + lactationAnnee1 + reductionFraisVeterinairesAnnee1
  const cumulImpactbrutAnnee2 = litreLaitAnnee2 + lactationAnnee2 + reductionFraisVeterinairesAnnee2
  const cumulImpactbrutAnnee3 = litreLaitAnnee3 + lactationAnnee3 + reductionFraisVeterinairesAnnee3
  const cumulImpactbrutAnnee4 = litreLaitAnnee4 + lactationAnnee4 + reductionFraisVeterinairesAnnee4

  /*
  Bornes litres de lait : min = 8000, max = 15000
  */
  const impactPondereLitreLait = () => {
    const nbrLitresMoyen = store.state.quotes.current.elevage.nbrLitresMoyen
    return (1 - (nbrLitresMoyen - 8000) / (15000 - 8000))
  }

  /*
  Bornes lactation : min = 2, max = 5
  */
  const impactPondereLactation = () => {
    const nbrLactationsMoyen = store.state.quotes.current.elevage.nbrLactationsMoyen
    return (1 - (nbrLactationsMoyen - 2) / (5 - 2))
  }

  /*
  prctPresenceEtable * impactPondereLitreLait * impactPondereLactation
  */
  const pourcentageCumuleEffets = store.state.quotes.current.elevage.prctPresenceEtable
    * impactPondereLitreLait() * impactPondereLactation()

  const impactsAnnuelsDecoteAnnee1  = cumulImpactbrutAnnee1 * pourcentageCumuleEffets
  const impactsAnnuelsDecoteAnnee2  = cumulImpactbrutAnnee2 * pourcentageCumuleEffets
  const impactsAnnuelsDecoteAnnee3  = cumulImpactbrutAnnee3 * pourcentageCumuleEffets
  const impactsAnnuelsDecoteAnnee4  = cumulImpactbrutAnnee4 * pourcentageCumuleEffets

  const cumulImpactsAnnuelsDecoteAnnee2 = impactsAnnuelsDecoteAnnee1 + impactsAnnuelsDecoteAnnee2
  const cumulImpactsAnnuelsDecoteAnnee3 = cumulImpactsAnnuelsDecoteAnnee2 + impactsAnnuelsDecoteAnnee3
  const cumulImpactsAnnuelsDecoteAnnee4 = cumulImpactsAnnuelsDecoteAnnee3 + impactsAnnuelsDecoteAnnee4
  const cumulImpactsAnnuelsDecoteAnnee5 = cumulImpactsAnnuelsDecoteAnnee4 + impactsAnnuelsDecoteAnnee4
  const cumulImpactsAnnuelsDecoteAnnee6 = cumulImpactsAnnuelsDecoteAnnee5 + impactsAnnuelsDecoteAnnee4
  const cumulImpactsAnnuelsDecoteAnnee7 = cumulImpactsAnnuelsDecoteAnnee6 + impactsAnnuelsDecoteAnnee4
  const cumulImpactsAnnuelsDecoteAnnee8 = cumulImpactsAnnuelsDecoteAnnee7 + impactsAnnuelsDecoteAnnee4
  const cumulImpactsAnnuelsDecoteAnnee9 = cumulImpactsAnnuelsDecoteAnnee8 + impactsAnnuelsDecoteAnnee4
  const cumulImpactsAnnuelsDecoteAnnee10 = cumulImpactsAnnuelsDecoteAnnee9 + impactsAnnuelsDecoteAnnee4

  let prixHT = 0
  produitsDevis.forEach(produit => {
    if(allProduits && allProduits[produit.produit]) {
      prixHT += allProduits[produit.produit].price * produit.qte
    }
  })

  // RI pour chacune des 10 années
  let ri = []
  ri.push(+(impactsAnnuelsDecoteAnnee1 / prixHT / 100).toFixed(2))
  ri.push(+(cumulImpactsAnnuelsDecoteAnnee2 / prixHT / 100).toFixed(2))
  ri.push(+(cumulImpactsAnnuelsDecoteAnnee3 / prixHT / 100).toFixed(2))
  ri.push(+(cumulImpactsAnnuelsDecoteAnnee4 / prixHT / 100).toFixed(2))
  ri.push(+(cumulImpactsAnnuelsDecoteAnnee5 / prixHT / 100).toFixed(2))
  ri.push(+(cumulImpactsAnnuelsDecoteAnnee6 / prixHT / 100).toFixed(2))
  ri.push(+(cumulImpactsAnnuelsDecoteAnnee7 / prixHT / 100).toFixed(2))
  ri.push(+(cumulImpactsAnnuelsDecoteAnnee8 / prixHT / 100).toFixed(2))
  ri.push(+(cumulImpactsAnnuelsDecoteAnnee9 / prixHT / 100).toFixed(2))
  ri.push(+(cumulImpactsAnnuelsDecoteAnnee10 / prixHT / 100).toFixed(2))

  let gains = []
  gains.push((prixHT * ri[0]) - prixHT)
  gains.push((prixHT * ri[1]) - prixHT)
  gains.push((prixHT * ri[2]) - prixHT)
  gains.push((prixHT * ri[3]) - prixHT)
  gains.push((prixHT * ri[4]) - prixHT)
  gains.push((prixHT * ri[5]) - prixHT)
  gains.push((prixHT * ri[6]) - prixHT)
  gains.push((prixHT * ri[7]) - prixHT)
  gains.push((prixHT * ri[8]) - prixHT)
  gains.push((prixHT * ri[9]) - prixHT)

  // Année de rentabilisation des gains
  const rentabilisationIndex = gains.findIndex((gain) => {
    return gain > 0
  })

  const rentabilisationAnnee = new Date().getFullYear() + rentabilisationIndex

  // Gains totaux à l'année 10
  gains = Math.round(gains[9])

  // Trésorerie cumulée par année avec un financement sur 7 ans
  let tresorerie7 = []
  tresorerie7.push(Math.round((prixHT * ri[0]) - ((prixHT * 129.89 / 10000) * 12)))
  tresorerie7.push(Math.round((prixHT * ri[1]) - ((prixHT * 129.89 / 10000) * 12) * 2))
  tresorerie7.push(Math.round((prixHT * ri[2]) - ((prixHT * 129.89 / 10000) * 12) * 3))
  tresorerie7.push(Math.round((prixHT * ri[3]) - ((prixHT * 129.89 / 10000) * 12) * 4))
  tresorerie7.push(Math.round((prixHT * ri[4]) - ((prixHT * 129.89 / 10000) * 12) * 5))
  tresorerie7.push(Math.round((prixHT * ri[5]) - ((prixHT * 129.89 / 10000) * 12) * 6))
  tresorerie7.push(Math.round((prixHT * ri[6]) - ((prixHT * 129.89 / 10000) * 12) * 7))
  tresorerie7.push(Math.round((prixHT * ri[7]) - ((prixHT * 129.89 / 10000) * 12) * 7))
  tresorerie7.push(Math.round((prixHT * ri[8]) - ((prixHT * 129.89 / 10000) * 12) * 7))
  tresorerie7.push(Math.round((prixHT * ri[9]) - ((prixHT * 129.89 / 10000) * 12) * 7))

  // Trésorerie cumulée par année avec un financement sur 10 ans
  let tresorerie10 = []
  tresorerie10.push(Math.round((prixHT * ri[0]) - ((prixHT * 94.27 / 10000) * 12)))
  tresorerie10.push(Math.round((prixHT * ri[1]) - ((prixHT * 94.27 / 10000) * 12) * 2))
  tresorerie10.push(Math.round((prixHT * ri[2]) - ((prixHT * 94.27 / 10000) * 12) * 3))
  tresorerie10.push(Math.round((prixHT * ri[3]) - ((prixHT * 94.27 / 10000) * 12) * 4))
  tresorerie10.push(Math.round((prixHT * ri[4]) - ((prixHT * 94.27 / 10000) * 12) * 5))
  tresorerie10.push(Math.round((prixHT * ri[5]) - ((prixHT * 94.27 / 10000) * 12) * 6))
  tresorerie10.push(Math.round((prixHT * ri[6]) - ((prixHT * 94.27 / 10000) * 12) * 7))
  tresorerie10.push(Math.round((prixHT * ri[7]) - ((prixHT * 94.27 / 10000) * 12) * 8))
  tresorerie10.push(Math.round((prixHT * ri[8]) - ((prixHT * 94.27 / 10000) * 12) * 9))
  tresorerie10.push(Math.round((prixHT * ri[9]) - ((prixHT * 94.27 / 10000) * 12) * 10))

  return {
    prixHT: Math.round(prixHT),
    ri,
    gains,
    rentabilisationAnnee,
    tresorerie7,
    tresorerie10
  }
}

export default { calculRi }
