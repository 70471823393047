<template>
  <div class="quote-summary">
    <PageTitle v-if="$route.name === 'QuotesValidate'" />

    <div class="page-title">
      <h1>Informations sur <strong>votre exploitation</strong></h1>
    </div>

    <div class="values">
      <div v-if="values.nombreLogettes" class="value">
        <p>Nombre de logettes :</p><strong>{{ values.nombreLogettes }}</strong>
      </div>
      <div v-if="values.nbrVachesTraite" class="value">
        <p>Nbre de vaches en traite :</p><strong>{{ values.nbrVachesTraite }}</strong>
      </div>
      <div v-if="stationsMeteo && stationsMeteo[values.stationMeteo]" class="value">
        <p>Station météo :</p><strong>{{ stationsMeteo[values.stationMeteo].name }}</strong>
      </div>
      <div v-if="values.nbrVachesTaries" class="value">
        <p>Nbre de vaches taries :</p><strong>{{ values.nbrVachesTaries }}</strong>
      </div>
      <div v-if="values.nbrLactationsMoyen" class="value">
        <p>Nombre de lactations moyen :</p><strong>{{ values.nbrLactationsMoyen }}</strong>
      </div>
      <div v-if="values.nbrGenisses" class="value">
        <p>Nbre de génisses : </p><strong>{{ values.nbrGenisses }}</strong>
      </div>
      <div v-if="values.largeurLogette" class="value">
        <p>Largeur de logettes :</p><strong>{{ values.largeurLogette }}m</strong>
      </div>
      <div v-if="values.venteGenisses" class="value">
        <p>Vente de génisses : (en nbre de génisses/an)</p><strong>{{ values.venteGenisses }}</strong>
      </div>
      <div v-if="taxos.couchage && taxos.couchage[values.couchage]" class="value">
        <p>Couchage : </p><strong>{{ taxos.couchage[values.couchage].name }}</strong>
      </div>
      <div v-if="values.nbrLitresMoyen" class="value">
        <p>Nbre de litres moyen :</p><strong>{{ values.nbrLitresMoyen }}</strong>
      </div>
      <div v-if="values.prixLitreLait" class="value">
        <p>Prix du litre de lait :</p><strong>{{ values.prixLitreLait }} €</strong>
      </div>
      <div class="value">
        <p>Présence de cailloux dans le bâtiment :</p><strong>{{ values.zoneCaillouteuse === '1' ? 'Oui' : 'Non' }}</strong>
      </div>
      <div v-if="values.nbrPrimipares" class="value">
        <p>Nombre de primipare :</p><strong>{{ values.nbrPrimipares }}</strong>
      </div>
      <div v-if="taxos.type_de_sols && taxos.type_de_sols[values.typeSols]" class="value">
        <p>Type de sol des couloirs :</p><strong>{{ taxos.type_de_sols[values.typeSols].name }}</strong>
      </div>
      <div v-if="taxos.type_de_traite && taxos.type_de_traite[values.typeTraite]" class="value">
        <p>Type de traite :</p><strong>{{ taxos.type_de_traite[values.typeTraite].name }}</strong>
      </div>
      <div v-if="taxos.type_de_raclage && taxos.type_de_raclage[values.typeRaclage]" class="value">
        <p>Type de raclage :</p><strong>{{ taxos.type_de_raclage[values.typeRaclage].name }}</strong>
      </div>
      <div v-if="values.prctPresenceEtable" class="value">
        <p>Temps de présence dans le bâtiment :</p><strong>{{ values.prctPresenceEtable }}%</strong>
      </div>
      <div v-if="values.nbrPlaceCornadis" class="value">
        <p>Nombre de places au cornadis :</p><strong>{{ values.nbrPlaceCornadis }}</strong>
      </div>
      <div
        v-for="(couloir, index) in couloirs"
        class="value"
      >
        <p>
          Couloir {{ index + 1 }}
        </p>
        <strong>L: {{ couloir.longueur }}m, l: {{ couloir.largeur }}m</strong>
      </div>
      <div v-if="taxos.type_de_ventilation && taxos.type_de_ventilation[values.typeVentilation]" class="value">
        <p>Type de ventilation :</p><strong>{{ taxos.type_de_ventilation[values.typeVentilation].name }}</strong>
      </div>
      <div v-if="taxos.type_de_batiment && taxos.type_de_batiment[values.typeBatiment]" class="value">
        <p>Type de bâtiment :</p><strong>{{ taxos.type_de_batiment[values.typeBatiment].name }}</strong>
      </div>
      <div v-if="taxos.isolation_de_toiture && taxos.isolation_de_toiture[values.isolationToiture]" class="value">
        <p>Isolation de toiture :</p><strong>{{ taxos.isolation_de_toiture[values.isolationToiture].name }}</strong>
      </div>
    </div>

    <button
      v-if="$route.name === 'QuotesValidate' ||
      $route.name === 'QuotesEditValidate' ||
      $route.name === 'QuotesDuplicateValidate'"
      @click="validateQuote" class="btn btn-red">
      Valider la simulation
    </button>

    <Chart v-if="$route.name === 'clientsListing'" />

    <div class="p-6">Nous sommes à votre écoute pour étudier votre projet !</div>
    <div class="w-full">*Hors main d’œuvre pose - Pompe à chaleur & transport.</div>
  </div>
</template>

<script>
import PageTitle from "@/components/commons/PageTitle";
import Chart from "@/components/quotes/summary/Chart";
import riHelper from "@/helpers/ri";

export default {
  name: "Summary",

  components: { Chart, PageTitle },

  computed: {
    values () {
      const quote = this.$store.state.quotes.current
      return { ...quote.elevage, ...quote.logettes, ...quote.batiment }
    },

    couloirs() {
      return this.values.couloirs.filter((couloir) => couloir.longueur || couloir.largeur)
    },

    taxos() {
      return this.$store.state.quotes.taxos
    },

    stationsMeteo() {
      return this.$store.state.quotes.stationsMeteo
    }
  },

  methods: {
    async validateQuote() {
      await this.$store.commit('SET_CLIENT_ID', this.$route.params.clientId)

      const riData = riHelper.calculRi()

      this.$store.commit('SET_RI', riData.ri)
      this.$store.commit('SET_PRIX_HT', riData.prixHT)
      this.$store.commit('SET_GAINS', riData.gains)
      this.$store.commit('SET_RENTABILISATION_ANNEE', riData.rentabilisationAnnee)
      this.$store.commit('SET_TRESORERIE7', riData.tresorerie7)
      this.$store.commit('SET_TRESORERIE10', riData.tresorerie10)

      if(this.$route.name === 'QuotesEditValidate') {
        await this.$store.dispatch('patchQuote', this.$route.params.quoteId)
        this.$store.commit('SET_HIGHLIGHTED_QUOTE', this.$route.params.quoteId)

      } else {
        await this.$store.dispatch('postQuote')
      }

      await this.$router.push({ name: 'clientsListing'})
    }
  }
}
</script>
