<template>
  <div class="main">
    <div class="w-full quote-elevage-form">
      <PageTitle />
      <form>
        <div class="form-row form-row-4">
          <div class="form-field">
            <input
              placeholder="Nbre de logettes :"
              type="text"
              v-model.trim="$v.form.nombreLogettes.$model"
              class="form-input" :class="{ 'border-red-700': $v.form.nombreLogettes.$error }">
            <span>Nombre de logettes :</span>
          </div>
          <div class="form-field">
            <input
                placeholder="Nbre de vaches en traite :"
                type="text"
                v-model.trim="$v.form.nbrVachesTraite.$model"
                class="form-input" :class="{ 'border-red-700': $v.form.nbrVachesTraite.$error }">
            <span>Nbre de vaches en traite :</span>
          </div>
          <div class="form-field">
            <input
                placeholder="Nbre de vaches taries :"
                type="text"
                v-model.trim="$v.form.nbrVachesTaries.$model"
                class="form-input" :class="{ 'border-red-700': $v.form.nbrVachesTaries.$error }">
            <span>Nbre de vaches taries :</span>
          </div>
          <div class="form-field">
            <input
                placeholder="Nbre de génisses :"
                type="text"
                v-model.trim="$v.form.nbrGenisses.$model"
                class="form-input" :class="{ 'border-red-700': $v.form.nbrGenisses.$error }">
            <span>Nbre de génisses :</span>
          </div>
        </div>
        <div class="form-row form-row-3">
          <div class="form-field">
            <input
                placeholder="Vente de génisses : (en nbre de génisses/an)"
                type="text"
                v-model.trim="$v.form.venteGenisses.$model"
                class="form-input" :class="{ 'border-red-700': $v.form.venteGenisses.$error }">
            <span>Vente de génisses : (en nbre de génisses/an)</span>
          </div>
          <div class="form-field">
            <input
                placeholder="Nbre de litres moyen :"
                type="text"
                v-model.trim="$v.form.nbrLitresMoyen.$model"
                class="form-input" :class="{ 'border-red-700': $v.form.nbrLitresMoyen.$error }">
            <span>Nbre de litres moyen :</span>
            <p
                v-if="!$v.form.nbrLitresMoyen.decimal"
                class="text-red-700 mt-2">Nombre décimal (séparateur = ".")</p>
          </div>
          <div class="form-field">
            <input
                placeholder="Prix du litre de lait :"
                type="text"
                v-model.trim="$v.form.prixLitreLait.$model"
                class="form-input" :class="{ 'border-red-700': $v.form.prixLitreLait.$error }">
            <span>Prix du litre de lait :</span>
            <p
                v-if="!$v.form.prixLitreLait.decimal"
                class="text-red-700 mt-2">Nombre décimal (séparateur = ".")</p>
          </div>
        </div>
        <div class="form-row form-row-3">
          <div class="form-field">
            <input
                placeholder="Nbre de primipares :"
                type="text"
                v-model.trim="$v.form.nbrPrimipares.$model"
                class="form-input" :class="{ 'border-red-700': $v.form.nbrPrimipares.$error }">
            <span>Nbre de primipares :</span>
          </div>
          <div class="form-field">
            <select
              v-model.trim="$v.form.typeTraite.$model"
              class="form-select" :class="{ 'border-red-700': $v.form.typeTraite.$error }">
              <option
                v-for="(option, index) in typeTraiteOptions"
                :key="index"
                :value="option.id"
                :disabled="!option.id"
              >
                {{ option.name }}
              </option>
            </select>
            <span class="select">Type de traite :</span>
          </div>
          <div class="form-field">
            <input
                placeholder="Temps de présence dans le bâtiment :"
                type="text"
                v-model.trim="$v.form.prctPresenceEtable.$model"
                class="form-input" :class="{ 'border-red-700': $v.form.prctPresenceEtable.$error }">
            <span>Temps de présence dans le bâtiment :</span>
            <p
                v-if="!$v.form.prctPresenceEtable.decimal"
                class="text-red-700 mt-2">Nombre décimal (séparateur = ".")</p>
          </div>
        </div>
        <div class="form-row form-row-2">
          <div class="form-field">
            <input
                placeholder="Nbre de lactations moyen :"
                type="text"
                v-model.trim="$v.form.nbrLactationsMoyen.$model"
                class="form-input" :class="{ 'border-red-700': $v.form.nbrLactationsMoyen.$error }">
            <span>Nbre de lactations moyen :</span>
            <p
                v-if="!$v.form.nbrLactationsMoyen.decimal"
                class="text-red-700 mt-2">Nombre décimal (séparateur = ".")</p>
          </div>
          <div class="form-field">
            <select
              v-model.trim="$v.form.stationMeteo.$model"
              class="form-select" :class="{ 'border-red-700': $v.form.stationMeteo.$error }">
              <option
                v-for="(option, index) in stationMeteoOptions"
                :key="index"
                :value="option.id"
                :disabled="!option.id"
              >
                {{ option.name }}
              </option>
            </select>
            <span class="select">Station météo :</span>
          </div>
        </div>
        <div class="form-submit">
          <button
              class="btn btn-red my-5"
              @click.prevent="handleSubmit">
            Valider
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/commons/PageTitle";
import { required, numeric, decimal } from 'vuelidate/lib/validators'

export default {
  components: {PageTitle},

  computed: {
    form() {
      return this.$store.state.quotes.current.elevage
    },

    typeTraiteOptions () {
      return this.$store.state.quotes.taxos.type_de_traite
    },

    stationMeteoOptions () {
      return this.$store.state.quotes.stationsMeteo
    }
  },

  validations: {
    form: {
      nombreLogettes: {
        numeric,
        required
      },
      nbrVachesTraite: {
        numeric,
        required
      },
      nbrVachesTaries: {
        numeric,
        required
      },
      nbrGenisses: {
        numeric,
        required
      },
      venteGenisses: {
        numeric,
        required
      },
      nbrLitresMoyen: {
        decimal,
        required
      },
      prixLitreLait: {
        decimal,
        required
      },
      nbrPrimipares: {
        numeric,
        required
      },
      typeTraite: {
        required
      },
      prctPresenceEtable: {
        decimal,
        required
      },
      nbrLactationsMoyen: {
        decimal,
        required
      },
      stationMeteo: {
        required
      },
    }
  },

  methods: {
    handleSubmit () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$store.commit('SET_ELEVAGE_VALUE', this.form)

        if(this.$route.name === 'QuotesEditElevage') {
          this.$router.push({ name: 'QuotesEditLogettes' })

        } else if (this.$route.name === 'QuotesDuplicateElevage') {
          this.$router.push({ name: 'QuotesDuplicateLogettes' })

        } else {
          this.$router.push({ name: 'QuotesLogettes' })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
