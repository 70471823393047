<template>
  <div>
    <BackBtn v-if="$route.name !== 'clientsListing'"/>
    <router-view />
  </div>
</template>

<script>
import BackBtn from "@/components/commons/BackBtn";
export default {
  name: "Clients",
  components: {BackBtn}
}
</script>

<style scoped>

</style>