const SET_CLIENTS = (state, value) => {
 state.listing = value
}

const SET_CLIENT = (state, value) => {
  state.current = value
}

const SET_HIGHLIGHTED_QUOTE = (state, value) => {
  state.listingHighlightedQuote = value
}

export default {
  SET_CLIENTS,
  SET_CLIENT,
  SET_HIGHLIGHTED_QUOTE
}
