<template>
  <div class="main">
    <div class="w-full quote-produit-form">
      <modal>
        <div slot="activator" class="quote-infos">
          <button class="btn btn-transparent btn-small">
            Voir les informations de la simulation
          </button>
        </div>
       <Validate />
      </modal>

      <PageTitle />

      <form>
        <div class="form-row form-row-2--large">
          <select
              v-model.trim="form[0].produit"
              class="form-select first"
              :class="{ 'border-red-700': errorFirstProduct }"
              @change="handleSelectProduct(0, $event)"
          >
            <option value="" selected>Sélectionnez un produit :</option>
            <option
                v-for="(option, index) in produitsOptions"
                :key="index"
                :value="option.id"
            >
              {{ option.name }}
            </option>
          </select>
          <input
            placeholder="Quantité :"
            type="text"
            v-model.trim="form[0].qte"
            class="form-input last"
            :class="{ 'border-red-700': errorFirstQte }"
          >
        </div>

        <div class="form-row form-row-2--large">
          <select
            v-model.trim="form[1].produit"
            class="form-select first"
            @change="handleSelectProduct(1, $event)"
          >
            <option value="" selected>Sélectionnez un produit :</option>
            <option
              v-for="(option, index) in produitsOptions"
              :key="index"
              :value="option.id"
            >
              {{ option.name }}
            </option>
          </select>
          <input
            placeholder="Quantité :"
            type="text"
            v-model.trim="form[1].qte"
            class="form-input last"
          >
        </div>

        <div class="form-row form-row-2--large">
          <select
            v-model.trim="form[2].produit"
            class="form-select first"
            @change="handleSelectProduct(2, $event)"
          >
            <option value="" selected>Sélectionnez un produit :</option>
            <option
              v-for="(option, index) in produitsOptions"
              :key="index"
              :value="option.id"
            >
              {{ option.name }}
            </option>
          </select>
          <input
            placeholder="Quantité :"
            type="text"
            v-model.trim="form[2].qte"
            class="form-input last"
          >
        </div>

        <div class="form-row form-row-2--large">
          <select
            v-model.trim="form[3].produit"
            class="form-select first"
            @change="handleSelectProduct(3, $event)"
          >
            <option value="" selected>Sélectionnez un produit :</option>
            <option
              v-for="(option, index) in produitsOptions"
              :key="index"
              :value="option.id"
            >
              {{ option.name }}
            </option>
          </select>
          <input
            placeholder="Quantité :"
            type="text"
            v-model.trim="form[3].qte"
            class="form-input last"
          >
        </div>

        <div class="form-row form-row-2--large">
          <select
            v-model.trim="form[4].produit"
            class="form-select first"
            @change="handleSelectProduct(4, $event)"
          >
            <option value="" selected>Sélectionnez un produit :</option>
            <option
              v-for="(option, index) in produitsOptions"
              :key="index"
              :value="option.id"
            >
              {{ option.name }}
            </option>
          </select>
          <input
            placeholder="Quantité :"
            type="text"
            v-model.trim="form[4].qte"
            class="form-input last"
          >
        </div>


        <div class="form-submit">
          <button
              class="btn btn-red my-5"
              @click.prevent="handleSubmit">
            Valider
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/commons/PageTitle";
import Validate from "@/components/quotes/summary/Validate";

export default {
  components: {Validate, PageTitle},

  data: function () {
    return {
      errorFirstProduct: false,
      errorFirstQte: false
    }
  },

  computed: {
    form: {
      get: function () {
        return this.$store.state.quotes.current.produits
      },
      set: function (newValue) {
      }
    },

    quoteLargeurLogette() {
      return this.$store.state.quotes.current.logettes.largeurLogette
    },

    quoteNombreLogettes() {
      return this.$store.state.quotes.current.elevage.nombreLogettes
    },

    surfacesCouloirsCumulees() {
      let surface = 0
      const couloirs = this.$store.state.quotes.current.logettes.couloirs
      couloirs.map((couloir) => {
        surface += couloir.longueur * couloir.largeur * couloir.qte
      })
      return surface
    },

    produits() {
      return this.$store.state.quotes.produits
    },

    produitsOptions() {
      return Object.values(this.produits).filter((produit) => produit.largeurLogette === this.quoteLargeurLogette || !produit.largeurLogette)
    }
  },

  methods: {
    handleSubmit () {
      if (!this.form[0].produit) {
        this.errorFirstProduct = true
        return
      }

      if (!this.form[0].qte) {
        this.errorFirstQte = true
        return
      }

      this.$store.commit('SET_PRODUITS_VALUE', this.form)

      if(this.$route.name === 'QuotesEditProduits') {
        this.$router.push({ name: 'QuotesEditValidate' })

      } else if (this.$route.name === 'QuotesDuplicateProduits') {
        this.$router.push({ name: 'QuotesDuplicateValidate' })

      } else {
        this.$router.push({ name: 'QuotesValidate' })
      }
    },

    handleSelectProduct(index, e) {
      const productId = e.target.value

      if(this.produits[productId]) {
        const productCategory = this.produits[productId] ? this.produits[productId].category : null
        const productUnit = this.produits[productId] ? this.produits[productId].unit : null

        if(productCategory === '8' && productUnit === '149') {
          this.form[index].qte = this.quoteNombreLogettes
          return

        } else if(productCategory === '153') {
          this.form[index].qte = this.surfacesCouloirsCumulees
          return
        }
      }
      this.form[index].qte = ''
    }
  },

  beforeMount() {
    this.$store.dispatch('getProduits')
  }
}
</script>

<style lang="scss" scoped>

</style>
