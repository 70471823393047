const SET_ELEVAGE_VALUE = (state, value) => {
  state.current.elevage = value;
}

const SET_LOGETTES_VALUE = (state, value) => {
  state.current.logettes = value;
}

const SET_BATIMENT_VALUE = (state, value) => {
  state.current.batiment = value;
}

const SET_PRODUITS_VALUE = (state, value) => {
  state.current.produits = value;
}

const SET_CLIENT_ID = (state, value) => {
  state.current.client.id = value;
}

const SET_TAXOS = (state, value) => {
  state.taxos = value;
}

const SET_PRODUITS = (state, value) => {
  state.produits = value;
}

const SET_STATIONS_METEO = (state, value) => {
  state.stationsMeteo = value;
}

const SET_RI_SETTINGS = (state, value) => {
  state.riSettings = value;
}

const SET_RI = (state, value) => {
  state.current.ri = value;
}

const SET_PRIX_HT = (state, value) => {
  state.current.prixHT = value;
}

const SET_GAINS = (state, value) => {
  state.current.gains = value;
}

const SET_RENTABILISATION_ANNEE = (state, value) => {
  state.current.rentabilisationAnnee = value;
}

const SET_TRESORERIE7 = (state, value) => {
  state.current.tresorerie7 = value;
}

const SET_TRESORERIE10 = (state, value) => {
  state.current.tresorerie10 = value;
}

const SET_QUOTE = (state, value) => {
  state.current = value;
}

export default {
  SET_ELEVAGE_VALUE,
  SET_LOGETTES_VALUE,
  SET_BATIMENT_VALUE,
  SET_PRODUITS_VALUE,
  SET_CLIENT_ID,
  SET_TAXOS,
  SET_PRODUITS,
  SET_STATIONS_METEO,
  SET_RI_SETTINGS,
  SET_RI,
  SET_PRIX_HT,
  SET_GAINS,
  SET_RENTABILISATION_ANNEE,
  SET_TRESORERIE7,
  SET_TRESORERIE10,
  SET_QUOTE
}
